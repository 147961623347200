export const environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyCwYTHdtmKLg2Kqf6Dhe6MqN8z9J-oJmI8',
		authDomain: 'rubiks-timesheets-demo.firebaseapp.com',
		databaseURL: 'https://rubiks-timesheets-demo.firebaseio.com',
		projectId: 'rubiks-timesheets-demo',
		storageBucket: 'rubiks-timesheets-demo.appspot.com',
		messagingSenderId: '141185116862',
		appId: '1:141185116862:web:ff7f973cce726d7bd90cef'
	},
};
